$DEFAULT_FONT: Arial, sans-serif;
$DEFAULT_MARGIN: 0px;
$VERY_SMALL_FONT: 8px;
$SMALL_FONT: 12px;
$SMALL_LABEL_FONT: 14px;
$REGULAR_FONT: 24px;
$BIG_FONT: 28px;
$BIGGER_FONT: 32px;
$DRAWER_WIDTH: 0px;

$BREAKPOINT_SO_SMARTPHONE: 700px;
$BREAKPOINT_SO_MD: 960px;
$BREAKPOINT_SO_LG: 1280px;
$BREAKPOINT_SO_LG_PLUS: 1500px;
$BREAKPOINT_SMARTPHONE: 599px;
$BREAKPOINT_MD: 959px;
$BREAKPOINT_LG: 1280px;
$LOGO_SMALL_WIDTH: 35px;
$LOGO_WIDTH: 50px;
$LOGO_HEIGHT: 50px;
$LOGO_BIG_WIDTH: 65px;
$LOGO_WIDTH_HUGE: 180px;
$LIGHT: 300;
$REGULAR: 400;
$MEDIUM: 500;
$SEMI_BOLD: 600;
$BOLD: 700;
$BLACK: 900;

:export {
    DEFAULT_FONT: $DEFAULT_FONT;
    DEFAULT_MARGIN: $DEFAULT_MARGIN;
    SMALL_FONT: $SMALL_FONT;
    SMALL_LABEL_FONT: $SMALL_LABEL_FONT;
    REGULAR_FONT: $REGULAR_FONT;
    BIG_FONT: $BIG_FONT;
    BIGGER_FONT: $BIGGER_FONT;
    DRAWER_WIDTH: $DRAWER_WIDTH;
    BREAKPOINT_SMARTPHONE: $BREAKPOINT_SMARTPHONE;
    BREAKPOINT_MD: $BREAKPOINT_MD;
    LOGO_SMALL_WIDTH: $LOGO_SMALL_WIDTH;
    LOGO_HEIGHT: $LOGO_HEIGHT;
    LOGO_WIDTH: $LOGO_WIDTH;
    LOGO_WIDTH_HUGE: $LOGO_WIDTH_HUGE;
    LOGO_BIG_WIDTH: $LOGO_BIG_WIDTH;
    LIGHT: $LIGHT;
    REGULAR: $REGULAR;
    MEDIUM: $MEDIUM;
    SEMI_BOLD: $SEMI_BOLD;
    BOLD: $BOLD;
    BLACK: $BLACK;
    VERY_SMALL_FONT: $VERY_SMALL_FONT;
}
