//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap');

//SalesOnline
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,300;1,400&family=Poppins:wght@400;500&family=Raleway:wght@400;600;700&display=swap");

// Brioso
@import url("https://use.typekit.net/wss1yld.css");

// Futura 
@import url("https://use.typekit.net/wss1yld.css");

//p-22 underground
@import url("https://use.typekit.net/wss1yld.css");

//pt serif
@import url("https://use.typekit.net/fsc2bjj.css");

//Mr eaves modern
@import url("https://use.typekit.net/gia4vas.css");

//brandon-grotesque, sans-serif 
//acumin-pro-condensed, sans-serif
@import url("https://use.typekit.net/qeu3ubz.css");

@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap");

@font-face {
    font-family: "foco";
    src: url(../assets/fonts/Foco-Regular.woff) format("woff");
    src: url(../assets/fonts/Foco-Regular.woff2) format("woff2");
}

/*@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins.woff) format("woff");
    src: url(../assets/fonts/Poppins.woff2) format("woff2");
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Regular.woff) format("woff");
    src: url(../assets/fonts/Poppins-Regular.woff2) format("woff2");
}*/

@font-face {
    font-family: "century-gothic";
    src: url(../assets/fonts/CenturyGothic.woff) format("woff");
    src: url(../assets/fonts/CenturyGothic.woff2) format("woff2");
}

@font-face {
    font-family: "sheila";
    font-style: normal;
    font-weight: normal;
    src: url(../assets/fonts/Sheila.woff) format("woff");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../assets/fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    }

/*@font-face {
    font-family: "Meno";
    font-style: normal;
    src: url(../assets/fonts/Meno-Bold.woff) format("woff");
    src: url(../assets/fonts/Meno-Bold.woff2) format("woff2");
}*/

@font-face {
    font-family: "europa";
    font-style: normal;
    font-weight: normal;
    src: url(../assets/fonts/EuropaRegular.woff) format("woff");
    src: url(../assets/fonts/EuropaRegular.woff2) format("woff2");
}

@font-face {
    font-family: "orpheuspro";
    src: url(../assets/fonts/OrpheusPro-Bold.woff) format("woff");
    src: url(../assets/fonts/OrpheusPro-Bold.woff2) format("woff2");
}

//Di seguito i font recuperati dal sito di Tecma

@import url("https://use.typekit.net/zkq7wus.css");

@font-face {
    font-family: p22-marcel-script-pro;
    src: url(https://use.typekit.net/af/3ebd1a/00000000000000003b9adb11/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/3ebd1a/00000000000000003b9adb11/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/3ebd1a/00000000000000003b9adb11/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: renata;
    src: url(https://use.typekit.net/af/3d294e/00000000000000003b9b09e9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/3d294e/00000000000000003b9b09e9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/3d294e/00000000000000003b9b09e9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: sheila;
    src: url(https://use.typekit.net/af/9b4626/00000000000000003b9b218e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/9b4626/00000000000000003b9b218e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/9b4626/00000000000000003b9b218e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: sheila;
    src: url(https://use.typekit.net/af/e761e1/00000000000000003b9b218f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/e761e1/00000000000000003b9b218f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/e761e1/00000000000000003b9b218f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: century-gothic;
    src: url(https://use.typekit.net/af/471ad1/00000000000000003b9b1f20/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/471ad1/00000000000000003b9b1f20/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/471ad1/00000000000000003b9b1f20/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: century-gothic;
    src: url(https://use.typekit.net/af/85a471/00000000000000003b9b1f21/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/85a471/00000000000000003b9b1f21/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/85a471/00000000000000003b9b1f21/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: century-gothic;
    src: url(https://use.typekit.net/af/fb6c60/00000000000000003b9b1f22/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/fb6c60/00000000000000003b9b1f22/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/fb6c60/00000000000000003b9b1f22/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: century-gothic;
    src: url(https://use.typekit.net/af/afc5c6/00000000000000003b9b1f23/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/afc5c6/00000000000000003b9b1f23/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/afc5c6/00000000000000003b9b1f23/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: aphrosine;
    src: url(https://use.typekit.net/af/4af4d2/00000000000000003b9b21a7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/4af4d2/00000000000000003b9b21a7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/4af4d2/00000000000000003b9b21a7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: aphrosine;
    src: url(https://use.typekit.net/af/ed73bd/00000000000000003b9b21a8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/ed73bd/00000000000000003b9b21a8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/ed73bd/00000000000000003b9b21a8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/6106bc/00000000000000003b9b033a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/d5b36a/00000000000000003b9b033b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/09a46f/00000000000000003b9b033d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/3bde18/00000000000000003b9b033c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/69890d/00000000000000003b9b0338/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/7b8eae/00000000000000003b9b0339/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/05b406/00000000000000003b9b033f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: foco;
    src: url(https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/0cf3ef/00000000000000003b9b033e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: bigmoore;
    src: url(https://use.typekit.net/af/600188/00000000000000003b9ae122/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/600188/00000000000000003b9ae122/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/600188/00000000000000003b9ae122/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: bigmoore;
    src: url(https://use.typekit.net/af/5d8890/00000000000000003b9ae123/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/5d8890/00000000000000003b9ae123/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/5d8890/00000000000000003b9ae123/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: relation-two;
    src: url(https://use.typekit.net/af/6d38a1/00000000000000003b9afa35/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/6d38a1/00000000000000003b9afa35/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/6d38a1/00000000000000003b9afa35/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: relation-two;
    src: url(https://use.typekit.net/af/a86da9/00000000000000003b9afa36/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/a86da9/00000000000000003b9afa36/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/a86da9/00000000000000003b9afa36/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: relation-one;
    src: url(https://use.typekit.net/af/a248d5/00000000000000003b9afa44/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/a248d5/00000000000000003b9afa44/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/a248d5/00000000000000003b9afa44/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: relation-one;
    src: url(https://use.typekit.net/af/a6cef7/00000000000000003b9afa45/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/a6cef7/00000000000000003b9afa45/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/a6cef7/00000000000000003b9afa45/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
            format("woff"),
        url(https://use.typekit.net/af/8d734b/00000000000000003b9b2040/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n1&v=3)
            format("opentype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
            format("woff"),
        url(https://use.typekit.net/af/385686/00000000000000003b9b2041/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i1&v=3)
            format("opentype");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
            format("woff"),
        url(https://use.typekit.net/af/4d5fe8/00000000000000003b9b2042/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3)
            format("opentype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
            format("woff"),
        url(https://use.typekit.net/af/72a1b1/00000000000000003b9b2043/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3)
            format("opentype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/2d3659/00000000000000003b9b2045/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/9395af/00000000000000003b9b2046/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/032ccd/00000000000000003b9b2047/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
            format("woff"),
        url(https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3)
            format("opentype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
            format("woff"),
        url(https://use.typekit.net/af/7391a1/00000000000000003b9b2049/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i5&v=3)
            format("opentype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
            format("woff"),
        url(https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3)
            format("opentype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
            format("woff"),
        url(https://use.typekit.net/af/a659fe/00000000000000003b9b204b/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3)
            format("opentype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/d562ce/00000000000000003b9b204c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/661dc5/00000000000000003b9b204d/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/375e66/00000000000000003b9b204e/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: neue-haas-grotesk-display;
    src: url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/622d21/00000000000000003b9b204f/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/de021d/00000000000000003b9af036/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/de021d/00000000000000003b9af036/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/de021d/00000000000000003b9af036/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/624f00/00000000000000003b9af037/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/624f00/00000000000000003b9af037/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff"),
        url(https://use.typekit.net/af/624f00/00000000000000003b9af037/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/78ca66/00000000000000003b9af038/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/78ca66/00000000000000003b9af038/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/78ca66/00000000000000003b9af038/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/f0a340/00000000000000003b9af039/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/f0a340/00000000000000003b9af039/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("woff"),
        url(https://use.typekit.net/af/f0a340/00000000000000003b9af039/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/8de0c1/00000000000000003b9af03a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/8de0c1/00000000000000003b9af03a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format("woff"),
        url(https://use.typekit.net/af/8de0c1/00000000000000003b9af03a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format("opentype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/0c5f2c/00000000000000003b9af03b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/0c5f2c/00000000000000003b9af03b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3)
            format("woff"),
        url(https://use.typekit.net/af/0c5f2c/00000000000000003b9af03b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3)
            format("opentype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/2b3e8b/00000000000000003b9af03c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/2b3e8b/00000000000000003b9af03c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/2b3e8b/00000000000000003b9af03c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/958a5b/00000000000000003b9af03d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/958a5b/00000000000000003b9af03d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
        url(https://use.typekit.net/af/958a5b/00000000000000003b9af03d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/a11f43/00000000000000003b9af03e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/a11f43/00000000000000003b9af03e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3)
            format("woff"),
        url(https://use.typekit.net/af/a11f43/00000000000000003b9af03e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3)
            format("opentype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/e21330/00000000000000003b9af03f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/e21330/00000000000000003b9af03f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3)
            format("woff"),
        url(https://use.typekit.net/af/e21330/00000000000000003b9af03f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3)
            format("opentype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/b4db1b/00000000000000003b9af040/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/b4db1b/00000000000000003b9af040/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/b4db1b/00000000000000003b9af040/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: meno-banner;
    src: url(https://use.typekit.net/af/caa905/00000000000000003b9af041/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("woff2"),
        url(https://use.typekit.net/af/caa905/00000000000000003b9af041/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("woff"),
        url(https://use.typekit.net/af/caa905/00000000000000003b9af041/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: italic;
}

/* devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiAyp8kv8JHgFVrJJLmE0tDMPKzSQ.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiAyp8kv8JHgFVrJJLmE0tMMPKzSQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiAyp8kv8JHgFVrJJLmE0tCMPI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmv1pVFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmv1pVGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmv1pVF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm21lVFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm21lVGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm21lVF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('Poppins Italic'), local('Poppins-Italic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrJJLucXtAKPY.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('Poppins Italic'), local('Poppins-Italic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrJJLufntAKPY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('Poppins Italic'), local('Poppins-Italic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrJJLucHtA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmg1hVFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmg1hVGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmg1hVF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmr19VFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmr19VGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmr19VF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmy15VFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmy15VGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmy15VF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm111VFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm111VGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm111VF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm81xVFteOcEg.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm81xVGdeOcEg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'), url(https://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm81xVF9eO.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: local('Poppins Thin'), local('Poppins-Thin'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrLPTucXtAKPY.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: local('Poppins Thin'), local('Poppins-Thin'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: local('Poppins Thin'), local('Poppins-Thin'), url(https://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLFj_Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins Black'), local('Poppins-Black'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLBT5Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins Black'), local('Poppins-Black'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins Black'), local('Poppins-Black'), url(https://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  