@import "styles/colors.scss";
@import "styles/variables.scss";

.login-form {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 1%;
    margin-left: 1%;
    padding: 3% 4% 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    border-radius: 10px;
    background-color: #f2efe9;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}

.login-margin-bottom {
    margin-bottom: 15px;
    /*display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding-top: 24px;
    padding-bottom: 24px;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #1197a6;
    margin-top: 5%;*/
}

.login-icon {
    @extend .login-margin-bottom;
    width: $LOGO_WIDTH_HUGE;
}

.login-button-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

#login-button {
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    padding: 18px 32px;
    border-radius: 100px;
    transition: all 650ms ease;
    font-family: foco, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #278b96;
    width: 80%;
    margin-top: 0;
    margin-bottom: 20px;
    flex: 0 0 auto;
}

.login-logo-vendor {
    width: $LOGO_WIDTH_HUGE;
    position: absolute;
    bottom: 10px;
}

.login-title {
    color: black;
    letter-spacing: 3px;
    font-size: 28px;
}

.login-hint {
    color: $GREY_TEXT;
    font-size: 13px;
}

.login-background-image {
    width: 100%;
    left: 0px;
    object-fit: cover;
    height: 100%;
}

.logo-container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    height: auto;
    margin-right: 20px;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.login-text {
    margin-top: 0px;
    font-size: 2.6vw;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    font-family: "Playfair Display", sans-serif;
    font-size: 1.8vw;
    line-height: 1.8vw;
    width: 80%;
}

.login-background {
    height: 100%;
    position: fixed;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
}

.login-header {
    height: 15vh;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    min-height: 100px;
}

.login-password-recover {
    width: 80%;
    margin-top: 5px;
    font-family: foco, sans-serif;
    color: $ARBOREA_COLORED_SECONDARY;
    font-size: 12px;
    line-height: 14px;
    > a {
        outline: 0;
        color: $ARBOREA_COLORED_SECONDARY;
    }
}

.change-password {
    text-decoration: underline;
    cursor: pointer;
}
