@import 'styles/colors';
@import 'styles/variables';

.header-wrapper {
  position: fixed;
  top: 0px;
  height: 9vh;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 100px;
  border-bottom: 1px solid #ded9d4;
}

.card-bread {
  font-size: $BIG_FONT;
  color: $USER_BACKGROUND;
}

.card-title {
  color: $USER_BACKGROUND;
  font-size: $BIG_FONT;
  font-weight: $SEMI_BOLD;
}

.top-header {
  width: calc(100% - 20px);
  padding: 10px;
  display: inline-flex;
  justify-content: space-between;
  justify-content: flex-end;
  height: calc(100% - 20px);
  align-items: center;
}

.left-header {
  float: left;
}

.center-header {
  display: flex;
  place-items: center;
  align-self: flex-start;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: $BREAKPOINT_MD) {
    left: 25%;
  }
  @media (max-width: $BREAKPOINT_SO_SMARTPHONE) {
    left: 28%;
    > img {
      transform: scale(0.7);
    }
  }
}

.right-header {
  position: relative;
  right: 2%;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}

.image-header {
  margin: 0;
  align-self: center;
  width: auto;
  height: 5.2vh;
  margin-bottom: 4px;
  min-height: 50px;
}

.header-half-bar {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
}

.go-to-website,
.logout-button {
  font-family: 'Playfair Display SC', sans-serif;
  color: $ARBOREA_COLORED_PRIMARY;
  text-decoration: none;
  text-transform: lowercase;
  cursor: pointer;
}

.logout-button {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid $ARBOREA_COLORED_PRIMARY;
  display: inline-flex;
  align-items: center;
  .logout-text {
    margin-right: 10px;
  }
  @media (max-width: $BREAKPOINT_SO_SMARTPHONE) {
    padding-left: 20px;
  }
}

.header-language-select {
  display: inline;
  .go-to-website.language-selector {
    text-transform: uppercase !important;
  }
}
