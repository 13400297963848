@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';

* {
  box-sizing: border-box;
}

body {
  font-family: $DEFAULT_FONT;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
  min-height: 100%;
  font-size: 14px;
  line-height: 20px;
  text-size-adjust: 100%;
  padding-right: 0 !important;
}
img {
}

.default-font {
  font-family: $DEFAULT_FONT;
}

.main-container {
  width: calc(100% - #{$DRAWER_WIDTH});
  margin-right: 0px;
  margin-left: #{$DRAWER_WIDTH};
  min-height: 100vh;
  @media (max-width: $BREAKPOINT_MD) {
    width: 100%;
    margin-left: 0px;
  }
  .header-wrapper {
    @media (max-width: $BREAKPOINT_SO_SMARTPHONE) {
      position: fixed !important;
      height: unset !important;
      min-height: 80px !important;
    }
  }
}

.body-wrapper {
  margin: 0px $DEFAULT_MARGIN 0px $DEFAULT_MARGIN;
}

.small-font-6 {
  font-size: 6px;
}

.small-font-8 {
  font-size: 8px;
}

.small-font-9 {
  font-size: 9px;
}

.small-font-10 {
  font-size: 10px;
}

.small-font-12 {
  font-size: 12px;
}

.elements-spacing {
  margin: 20px 5px;
}

.MT-0 {
  margin-top: 0px;
}

.MT-10 {
  margin-top: 10px;
}

.MR-10 {
  margin-right: 10px;
}

.ML-10 {
  margin-left: 10px;
}

.MR-30 {
  @media (min-width: $BREAKPOINT_MD) {
    margin-right: 30px;
  }
}

.width-100 {
  width: 100%;
}

.full-width {
  @extend .width-100;
  width: -webkit-fill-available;
  width: -moz-available;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-column-justified {
  @extend .flex-column;
  justify-content: space-between;
}

.flex-centered {
  @extend .flex;
  align-items: center;
}

.flex-centered-between {
  @extend .flex-centered;
  justify-content: space-between;
}

.flex-centered-between-wrapped {
  @extend .flex-centered-between;
  flex-wrap: wrap;
}

.mobile-flex-column {
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    flex-direction: column;
  }
}

.flex-wrap-container {
  @extend .flex;
  flex-wrap: wrap;
}

.flex-container-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-row-3 {
  flex-basis: 30%;
}

.input-text {
  margin: 5px !important;
  background-color: white;
  width: 80%;
}

.image-size {
  object-fit: scale-down;
  object-position: center center;
  max-width: 100%;
  max-height: 400px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  @extend .default-font;
  font-weight: $LIGHT !important;
}

.regular {
  @extend .default-font;
  font-weight: $REGULAR !important;
}

.medium {
  @extend .default-font;
  font-weight: $MEDIUM !important;
}

.semi-bold {
  @extend .default-font;
  font-weight: $SEMI_BOLD !important;
}

.bold {
  @extend .default-font;
  font-weight: $BOLD !important;
}

.black {
  @extend .default-font;
  font-weight: $BLACK !important;
}

.unselected {
  color: $DISABLED !important;
}

.selected {
  color: $DEFAULT_TEXT !important;
}

.backdrop-color {
  background-color: $BACKGROUNDS;
}

.backdrop-transparent {
  background-color: $BACKGROUNDS_TRANSPARENT;
}

.default-background {
  background-color: $BACKGROUNDS;
}

.more-z {
  z-index: 101 !important;
}

.table-container {
  padding: 0 !important;
}

.color-white {
  color: white !important;
  fill: white !important;
}

.libero {
  @extend .color-white;
  background-color: $LIBERO !important;
}

.interesse {
  @extend .color-white;
  background-color: $INTERESSE !important;
}

.proposta {
  @extend .color-white;
  background-color: $PROPOSTA !important;
}

.compromesso {
  @extend .color-white;
  background-color: $COMPROMESSO !important;
}

.rogitato {
  @extend .color-white;
  background-color: $ROGITATO !important;
}

.opzionato {
  @extend .color-white;
  background-color: $OPZIONATO !important;
}

.search-button {
  @extend .color-white;
  background-color: $SEARCH_BUTTON !important;
  padding: 10px !important;
}

.modify-button {
  @extend .search-button;
}

.add-button {
  @extend .search-button;
}

.remove-button {
  @extend .color-white;
  padding: 10px !important;
  background-color: $EDITING_COLOR !important;
}

.icons-in-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px !important;
  padding: 0px !important;
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

.user-background {
  background-color: $USER_BACKGROUND !important;
}

.smaller-font {
  font-size: $SMALL_LABEL_FONT;
}

.flex-grow-1 {
  flex-grow: 1;
}

.modal-custom-container {
  padding: 5px 10px;
  margin: 20px 15px 20px 0px;
  border-radius: 0px !important;
  @media (max-width: $BREAKPOINT_SO_MD) {
    margin: 0;
    width: 100% !important;
  }
}

.add-appartments-modal-content {
  min-height: 200px;
  min-width: 400px;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    min-width: unset;
  }
}

.custom-listbox {
  background-color: $SELECT_BACKGROUND;
  color: $USER_DATA_COLOR;
}

.list-divider {
  border-bottom: 1px solid $USER_DATA_COLOR;
}

.loader-wrapper {
  min-height: 60px;
}

.absolute-position {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.absolute-position-relative-content-loader {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.relative {
  position: relative;
}

.MuiSnackbar-root {
  z-index: 100000000 !important;
}

.snackbar-body {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $COLOR_PRIMARY;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.snackbar-body-error {
  color: $COLOR_SECONDARY;
  font-weight: $MEDIUM;
  background: $ERROR_COLOR;
  border-radius: 25px;
  text-align: center;
  padding: 10px;
  min-width: 100px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}

.magnified-container {
  background-color: white;
  cursor: zoom-in !important;
}

.zoom-options {
  align-self: normal;
  margin-right: 5px;
}

.image-zoom-container {
  width: fit-content;
}

.image-transformcomponent-container {
  border: 2px solid black;
}

.zoom-button {
  background: $COLOR_PRIMARY;
  color: $COLOR_SECONDARY;
}

.ellipsis {
  text-overflow: ellipsis;
}

.justify-center {
  justify-content: center;
}

.rotate-180 {
  transform: rotate(180deg);
}

.secondary-background {
  background-color: $ARBOREA_BACKGROUND_SECONDARY !important;
}

.special-bottom {
  display: none;
}

.background-color-1 {
  background-color: $ARBOREA_BACKGROUND_COLOR_1 !important;
}

.background-color-2 {
  background-color: $ARBOREA_BACKGROUND_COLOR_1 !important;
}

.text-color-1 {
  color: $ARBOREA_TEXT_COLOR_1 !important;
}

.logo {
  width: 13vw;
  max-width: 250px;
  min-width: 200px;
  height: auto;
}

.modal_custom_container.reserve-dialog {
  .modal_content {
    margin-bottom: 20px !important;
  }
}

.custom-button {
  padding: 18px 32px;
  border-radius: 100px;
  transition: all 650ms ease;
  font-family: foco, sans-serif;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  padding-top: 12px;
  padding-right: 40px;
  padding-bottom: 12px;
  justify-content: center;
  align-items: center;
  background-color: $ARBOREA_COLORED_SECONDARY;
  font-size: 12px;
  text-decoration: none;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    background-color: #03474d;
  }
  img {
    height: 35px;
    margin-right: 20px;
    padding-right: 0px;
  }
  &.delete {
    margin-top: 5%;
    margin-right: 5px;
    margin-left: 5px;
    flex: 1;
    text-align: center;
  }
}

.black-overlay {
  @media (max-width: 699px) {
    align-items: center;
    display: flex;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    justify-content: center;
    font-size: 2.5vw;
    & + * {
      display: none;
    }
  }
  display: none;
}

.MuiSnackbarContent-message {
  color: white;
}

.white-loader {
  background-color: white;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-form-confirm-vendor-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 2vw;
}

.title-center > .modal-title {
  display: flex;
  justify-content: center;
}

.zoom-call-button {
  background-color: #008cff;
  position: fixed;
  top: 24vh;
  right: 0;
  bottom: auto;
  display: flex;
  padding: 10px 20px 10px 15px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 100;
  height: 50px;

  &:hover {
  }

  &--icon {
    height: 40px;
    margin-right: 10px;
  }
  &--txt {
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.15;
  }
}

.zoom-call-iframe {
  width: 800px;
  height: 600px;
  min-width: 800px;
  margin: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: none;
}

.loader-wait-suspended {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

* + .loader-wait-suspended {
  display: none;
}

.no-border {
  border: 0px !important;
}

.language-select-container-public {
  position: fixed;
  top: calc(max(50px, 7.5vh));
  transform: translateY(-50%);
  right: 10px;
  z-index: 15;
  .go-to-website.language-selector {
    text-transform: uppercase !important;
  }
}

.eye-view-pdf{
  cursor: pointer;
  margin-right: 20px;
  width: 25px;
  height: 25px;  
}
.eye-view-pdf:hover {
  opacity: 0.2;
}
.eye-view-tooltip {
  background: rgba(37, 37, 37, 0.9) !important;
  border-radius: 0px!important;
  padding: 8px;
  color: white;
  font-size: 13px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  min-height: 30px;
  margin: 0px!important;
}
.home-configurator-button{
  margin: 0px;
  height: 40px;
  font-size: 12px;
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px 12px 25px;
  text-transform: capitalize;
}

.input-clients-form.videocall-sync-input{
  height: unset;
}

.my-home-form-compra{
  height: 100%;
}

.grey-custom {
  color:#B6B6B6;
}

.home-configuration-button-container{
  display: flex;
  align-items: center;
}

.home-configuration-button-lock{
  color: #A9A9A9;
  background: #E1E1E1;
}
.home-configuration-button-unlock{
  color: white;
  background: #333333;
  cursor: pointer;
}
.modal-cross-button{
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 0px;
}
.modal-cross-button:before, .modal-cross-button:after {
  position: absolute;
  content: ' ';
  height: 18px;
  width: 1.5px;
  background-color: #333;
  cursor: pointer;
}
.modal-cross-button:before{
  transform: rotate(45deg);
}
.modal-cross-button:after {
  transform: rotate(-45deg);
}

.send-configuration-modal{
  min-width: 420px;
  min-height: 200px;
  border-radius: 0px !important;
  .modal-title{
    font-size: 18px;
    font-weight: 700 !important;
    padding: 32px 32px 16px 32px;
  }
  .modal-content{
    padding: 0px 32px 24px 32px;
    max-width: 420px;
    .send-configuration-modal-message{
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }
  }
  .modal-actions{
    padding: 0px 32px 32px 32px !important;
    justify-content: space-between;
    .cancel-config-button{
      font-size: 12px;
      font-weight: 600;
      background-color: white!important;
      color: #333333;
      border-radius: 0px;
      border: 1px solid #6BC1B4;
      height: 40px;
      text-transform: capitalize;
      min-width: 120px;
      margin-right: 25px;
      box-shadow: none;
      cursor: pointer;
    }
    .send-config-button{
      font-size: 12px;
      font-weight: 600;
      background-color: #6BC1B4;
      color: white;
      height: 40px;
      border: 1px solid #6BC1B4;
      border-radius: 0px;
      text-transform: capitalize;
      min-width: 120px;
      margin-left: 0px;
      box-shadow: none;
      cursor: pointer;
    }
  }
}