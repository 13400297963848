$COLOR_PRIMARY: #000000;
$COLOR_SECONDARY: #ffffff;
$CARD_BACKGROUND: #ffffff;
$CARD_BACKGROUND_LIGHT: #ffffff;
$DEFAULT_FONT_COLOR: #333;
$GREY_TEXT: #bdc1c5;
$DEFAULT_TEXT: #000000;
$DISABLED: #a7a7a7;
$DISABLED_DARK: #43425d;
$BACKGROUND_PAGE: #fff;
$BACKGROUNDS: #f5f5fc;
$BACKGROUNDS_TRANSPARENT: rgba(245, 245, 252, 0.4);
$COMPROMESSO:#F77171;
$LIBERO:#84CE95;
$OPZIONATO:#F0BD48;
$ROGITATO:#662CFA;
$PROPOSTA:#FF975B;
$BORDER_AVATAR: #E0E0E0;
$INTERESSE:#64C0EB;
$SEARCH_BUTTON: #43425D;
$USER_BACKGROUND: #43425D;
$INPUT_COLOR: #333333;
$USER_DATA_COLOR: #ffffff;
$EDITING_COLOR: #f35959;
$ERROR_COLOR: #f35959; 
$LIGHT_FONT_COLOR:rgba(77,79,82, 0.5); //#4D4F5C;
$SELECT_BACKGROUND: #66657C;
$CALENDAR_HEADER: #A3A6B4;
$CALENDAR_TODAY: #F5F6FA;
$DROPZONE_BORDER: #C9C9C9;



$ARBOREA_BACKGROUND_COLOR_1: #f2efe9;
$ARBOREA_TEXT_COLOR_1: #03474d;
$ARBOREA_BACKGROUND_SECONDARY: #e6ded4;
$ARBOREA_COLORED_PRIMARY: #8b914b;
$ARBOREA_COLORED_SECONDARY: #1197a6;

$FEDRO_BACKGROUND_COLOR_1: #fbf9f4;
$FEDRO_BACKGROUND_COLOR_2: #d8c395;
$FEDRO_TABLE_BACKGROUND_COLOR: rgba(216, 195, 149, 0.2);
$FEDRO_TEXT_COLOR_1: #FFF;

$SEIMILANO_BACKGROUND_COLOR_1: #08353b;
$SEIMILANO_BACKGROUND_COLOR_2: #abd190;
$SEIMILANO_TABLE_BACKGROUND_COLOR: #f5f4f2;
$SEIMILANO_TEXT_COLOR_1: #FFF;

$SCALOHOUSE_BACKGROUND_COLOR_1: #2a273f;
$SCALOHOUSE_TABLE_BACKGROUND_COLOR: rgba(140, 155, 156, 0.2);

$GAROFALOPAISIELLO_BACKGROUND_COLOR_1: #326464;
$GAROFALOPAISIELLO_BACKGROUND_COLOR_2: #1edb6e;
$GAROFALOPAISIELLO_BACKGROUND_COLOR_3: #b3a99f;
$GAROFALOPAISIELLO_TABLE_BACKGROUND_COLOR: #f5f4f2;
$GAROFALOPAISIELLO_TEXT_COLOR_1: #333;

$SEIMILANO_BACKGROUND_COLOR_1: #08353b;
$SEIMILANO_BACKGROUND_COLOR_2: #abd190;
$SEIMILANO_TABLE_BACKGROUND_COLOR: #f5f4f2;
$SEIMILANO_TEXT_COLOR_1: #FFF;
$GREENUPLIVING_BACKGROUND_COLOR_1: #16373c;
$GREENUPLIVING_BACKGROUND_COLOR_2: #e1d8c1;
$GREENUPLIVING_TEXT_COLOR_1: #16373c;
$GREENUPLIVING_COLORED_PRIMARY: #b9a136;
$GREENUPLIVING_COLORED_SECONDARY: #b6523d;
$GREENUPLIVING_TABLE_BACKGROUND_COLOR: #e1d8c1;

$LEO_BACKGROUND_COLOR_1: #fff;
$LEO_BACKGROUND_COLOR_2: #f0f0f0;
$LEO_TABLE_BACKGROUND_COLOR: #f0f0f0;
$LEO_TEXT_COLOR_1: #c09f40;

$DIMOREMILANESI_BACKGROUND_COLOR_1: #422120;
$DIMOREMILANESI_BACKGROUND_COLOR_2: #8f9faa;
$DIMOREMILANESI_BACKGROUND_COLOR_3: #e7cdb5;
$DIMOREMILANESI_TABLE_BACKGROUND_COLOR: rgba(140, 155, 156, 0.2);
$DIMOREMILANESI_TEXT_COLOR_1: #ffe4cc;
$DIMOREMILANESI_TEXT_COLOR_2: #422120;

$CENTROPARCO_BACKGROUND_COLOR_1: #819787;
$CENTROPARCO_BACKGROUND_COLOR_2: #b58819;
$CENTROPARCO_BACKGROUND_COLOR_3:rgba(186, 174, 160, 0.2);
$CENTROPARCO_TABLE_BACKGROUND_COLOR: #f0f0f0;
$CENTROPARCO_TEXT_COLOR_1:  #b58819;
$CENTROPARCO_TEXT_COLOR_2: #422120;

$LESNIVEAUX_BACKGROUND_COLOR_1: rgba(54, 61, 60, 1);
$LESNIVEAUX_BACKGROUND_COLOR_2: #ad8a68;
$LESNIVEAUX_BACKGROUND_COLOR_3: rgba(132, 100, 70, 0.24);
$LESNIVEAUX_TABLE_BACKGROUND_COLOR: #f9f3ee;
$LESNIVEAUX_TEXT_COLOR_1: #ad8a68;
$LESNIVEAUX_TEXT_COLOR_2: #422120;

$PROXIMALIVING_BACKGROUND_COLOR_1: #513236;
$PROXIMALIVING_BACKGROUND_COLOR_2: #9C505B;
$PROXIMALIVING_BACKGROUND_COLOR_3:#EFE0CA;
$PROXIMALIVING_TABLE_BACKGROUND_COLOR: #f4efeb;
$PROXIMALIVING_TEXT_COLOR_1:  #9C505B;
$PROXIMALIVING_TEXT_COLOR_2: #422120;

$THE_NEST_BACKGROUND_COLOR_1: #0D2B35;
$THE_NEST_BACKGROUND_COLOR_2: #D9E0DD;
$THE_NEST_BACKGROUND_COLOR_3: #B2EEA8;
$THE_NEST_TABLE_BACKGROUND_COLOR: #A58468; 
$THE_NEST_TEXT_COLOR_1:  #000000;

$GS_TOWER_BACKGROUND_COLOR_1: rgb(49, 174, 121);
$GS_TOWER_BACKGROUND_COLOR_2: rgb(210, 232, 217);
$GS_TOWER_BACKGROUND_COLOR_3: rgb(241, 248, 243);
$GS_TOWER_BACKGROUND_COLOR_4: #143d21;
$GS_TOWER_TABLE_BACKGROUND_COLOR: #ffffff; 
$GS_TOWER_TEXT_COLOR_1:  #000000;

:export {
    // Export the color palette to make it accessible to JS
    COLOR_PRIMARY: $COLOR_PRIMARY;
    COLOR_SECONDARY: $COLOR_SECONDARY;
    CARD_BACKGROUND: $CARD_BACKGROUND;
    GREY_TEXT: $GREY_TEXT;
    DEFAULT_TEXT: $DEFAULT_TEXT;
    CARD_BACKGROUND_LIGHT: $CARD_BACKGROUND_LIGHT;
    DEFAULT_FONT_COLOR: $DEFAULT_FONT_COLOR;
    DISABLED: $DISABLED;
    DISABLED_DARK: $DISABLED_DARK;
    BACKGROUND_PAGE: $BACKGROUND_PAGE;
    BACKGROUNDS: $BACKGROUNDS;
    BACKGROUNDS_TRANSPARENT: $BACKGROUNDS_TRANSPARENT;
    INTERESSE: $INTERESSE;
    LIBERO: $LIBERO;
    OPZIONATO: $OPZIONATO;
    ROGITATO: $ROGITATO;
    COMPROMESSO: $COMPROMESSO;
    PROPOSTA: $PROPOSTA;
    INTERESSE: $INTERESSE;
    SEARCH_BUTTON: $SEARCH_BUTTON;
    USER_BACKGROUND: $USER_BACKGROUND;
    USER_DATA_COLOR: $USER_DATA_COLOR;
    INPUT_COLOR: $INPUT_COLOR;
    EDITING_COLOR: $EDITING_COLOR;
    LIGHT_FONT_COLOR: $LIGHT_FONT_COLOR;
    SELECT_BACKGROUND: $SELECT_BACKGROUND;
    BORDER_AVATAR: $BORDER_AVATAR;
    ERROR_COLOR: $ERROR_COLOR;
    CALENDAR_HEADER: $CALENDAR_HEADER;
    CALENDAR_TODAY: $CALENDAR_TODAY;
    DROPZONE_BORDER: $DROPZONE_BORDER;
    ARBOREA_COLORED_PRIMARY: $ARBOREA_COLORED_PRIMARY;
    ARBOREA_COLORED_SECONDARY: $ARBOREA_COLORED_SECONDARY;
    ARBOREA_BACKGROUND_COLOR_1: $ARBOREA_BACKGROUND_COLOR_1;
    ARBOREA_BACKGROUND_SECONDARY: $ARBOREA_BACKGROUND_SECONDARY;
    FEDRO_BACKGROUND_COLOR_1: $FEDRO_BACKGROUND_COLOR_1;
    FEDRO_BACKGROUND_COLOR_2: $FEDRO_BACKGROUND_COLOR_2;
    FEDRO_TABLE_BACKGROUND_COLOR: $FEDRO_TABLE_BACKGROUND_COLOR;
    SCALOHOUSE_TABLE_BACKGROUND_COLOR: $SCALOHOUSE_TABLE_BACKGROUND_COLOR;
    SEIMILANO_BACKGROUND_COLOR_1: $SEIMILANO_BACKGROUND_COLOR_1;
    SEIMILANO_BACKGROUND_COLOR_2: $SEIMILANO_BACKGROUND_COLOR_2;
    SEIMILANO_TABLE_BACKGROUND_COLOR: $SEIMILANO_TABLE_BACKGROUND_COLOR;
    SEIMILANO_TEXT_COLOR_1: $SEIMILANO_TEXT_COLOR_1;
    GAROFALOPAISIELLO_BACKGROUND_COLOR_1: $GAROFALOPAISIELLO_BACKGROUND_COLOR_1;
    GAROFALOPAISIELLO_BACKGROUND_COLOR_2: $GAROFALOPAISIELLO_BACKGROUND_COLOR_2;
    GAROFALOPAISIELLO_BACKGROUND_COLOR_3: $GAROFALOPAISIELLO_BACKGROUND_COLOR_3;
    GAROFALOPAISIELLO_TABLE_BACKGROUND_COLOR: $GAROFALOPAISIELLO_TABLE_BACKGROUND_COLOR;
    GAROFALOPAISIELLO_TEXT_COLOR_1: $GAROFALOPAISIELLO_TEXT_COLOR_1;
    GREENUPLIVING_BACKGROUND_COLOR_1: $GREENUPLIVING_BACKGROUND_COLOR_1;
    GREENUPLIVING_BACKGROUND_COLOR_2: $GREENUPLIVING_BACKGROUND_COLOR_2;
    GREENUPLIVING_TEXT_COLOR_1: $GREENUPLIVING_TEXT_COLOR_1;
    GREENUPLIVING_COLORED_PRIMARY: $GREENUPLIVING_COLORED_PRIMARY;
    GREENUPLIVING_COLORED_SECONDARY: $GREENUPLIVING_COLORED_SECONDARY;
    GREENUPLIVING_TABLE_BACKGROUND_COLOR: $GREENUPLIVING_TABLE_BACKGROUND_COLOR;
    LEO_BACKGROUND_COLOR_1: $LEO_BACKGROUND_COLOR_1;
    LEO_BACKGROUND_COLOR_2: $LEO_BACKGROUND_COLOR_2;
    LEO_TABLE_BACKGROUND_COLOR: $LEO_TABLE_BACKGROUND_COLOR;
    LEO_TEXT_COLOR_1: $LEO_TEXT_COLOR_1;
    DIMOREMILANESI_BACKGROUND_COLOR_1: $DIMOREMILANESI_BACKGROUND_COLOR_1;
    DIMOREMILANESI_BACKGROUND_COLOR_2: $DIMOREMILANESI_BACKGROUND_COLOR_2;
    DIMOREMILANESI_BACKGROUND_COLOR_3: $DIMOREMILANESI_BACKGROUND_COLOR_3;
    DIMOREMILANESI_TABLE_BACKGROUND_COLOR: $DIMOREMILANESI_TABLE_BACKGROUND_COLOR;
    DIMOREMILANESI_TEXT_COLOR_1: $DIMOREMILANESI_TEXT_COLOR_1;
    DIMOREMILANESI_TEXT_COLOR_2: $DIMOREMILANESI_TEXT_COLOR_2;
    CENTROPARCO_BACKGROUND_COLOR_1: $CENTROPARCO_BACKGROUND_COLOR_1;
    CENTROPARCO_BACKGROUND_COLOR_1: $CENTROPARCO_BACKGROUND_COLOR_2;
    CENTROPARCO_BACKGROUND_COLOR_1: $CENTROPARCO_BACKGROUND_COLOR_3;
    CENTROPARCO_TABLE_BACKGROUND_COLOR: $CENTROPARCO_TABLE_BACKGROUND_COLOR;
    CENTROPARCO_TEXT_COLOR_1: $CENTROPARCO_TEXT_COLOR_1;
    CENTROPARCO_TEXT_COLOR_2: $CENTROPARCO_TEXT_COLOR_2;
    LESNIVEAUX_BACKGROUND_COLOR_1: $LESNIVEAUX_BACKGROUND_COLOR_1;
    LESNIVEAUX_BACKGROUND_COLOR_2: $LESNIVEAUX_BACKGROUND_COLOR_2;
    LESNIVEAUX_BACKGROUND_COLOR_3: $LESNIVEAUX_BACKGROUND_COLOR_3;
    LESNIVEAUX_TABLE_BACKGROUND_COLOR: $LESNIVEAUX_TABLE_BACKGROUND_COLOR;
    LESNIVEAUX_TEXT_COLOR_1: $LESNIVEAUX_TEXT_COLOR_1;
    LESNIVEAUX_TEXT_COLOR_2: $LESNIVEAUX_TEXT_COLOR_2;
    PROXIMALIVING_BACKGROUND_COLOR_1: $PROXIMALIVING_BACKGROUND_COLOR_1;
    PROXIMALIVING_BACKGROUND_COLOR_2: $PROXIMALIVING_BACKGROUND_COLOR_2;
    PROXIMALIVING_BACKGROUND_COLOR_3: $PROXIMALIVING_BACKGROUND_COLOR_3;
    PROXIMALIVING_TABLE_BACKGROUND_COLOR: $PROXIMALIVING_TABLE_BACKGROUND_COLOR;
    PROXIMALIVING_TEXT_COLOR_1: $PROXIMALIVING_TEXT_COLOR_1;
    PROXIMALIVING_TEXT_COLOR_2: $PROXIMALIVING_TEXT_COLOR_2;
    THE_NEST_BACKGROUND_COLOR_1: $THE_NEST_BACKGROUND_COLOR_1;
    THE_NEST_BACKGROUND_COLOR_2: $THE_NEST_BACKGROUND_COLOR_2;
    THE_NEST_BACKGROUND_COLOR_3: $THE_NEST_BACKGROUND_COLOR_3;
    THE_NEST_TABLE_BACKGROUND_COLOR: $THE_NEST_TABLE_BACKGROUND_COLOR;
    THE_NEST_TEXT_COLOR_1: $THE_NEST_TEXT_COLOR_1;
}